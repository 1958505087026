import { makeStyles, Theme } from "@material-ui/core";
import { BorderAll } from "@material-ui/icons";

export const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
  detailContainer: {
    margin: "2rem 0 2rem 2rem",
    width: "22.875rem",
    height: "16.625rem",
    backgroundColor: ({ color }) => color,
    boxShadow: "4px 4px 15px 5px rgba(0, 0, 0, 0.15)",
    padding: "2.125rem 0 0 0",
    borderRadius: "20px 20px 0 0",
    [theme.breakpoints.down(1280)]: {
      margin: "0 0 0 0rem",
      padding: "2rem 0 0 0",
      width: "100%",
      height: "16.625rem",
      boxShadow: "none",
      borderRadius: "0 0 0 0",
    },
  },
  detailContainerInfo: {
    display: "flex",
    flexDirection: "row",
    margin: "0 0 1.5rem 0",
    justifyContent: "space-between",
  },
  containerBottomEnd: {
    backgroundColor: "#F4F3F3",
    width: "22.875rem",
    height: "35.438rem",
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
    borderRadius: "30px",
    [theme.breakpoints.down(1280)]: {
      width: "100%",
      boxShadow: "none",
      borderRadius: "30px 30px 0 0",
    },
  },
  buttonBackContainer: {
    width: "3.25rem",
    height: "3.25rem",
    backgroundColor: "rgba(0, 0, 0, 0.45)",
    borderRadius: "7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 0 0 2rem",

    "& svg": {
      width: "4rem",
      height: "4rem",
      color: "white",
    },
  },
  buttonBack1: {},
  bankIcon: {
    width: "4.25rem",
    height: "4.25rem",
    marginRight: "43%",
  },
  bankTitleAndButton: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1rem 2rem 0.3rem 2rem",
    paddingTop: "1rem",
  },
  bankTitle: {
    margin: "0 0 0 0",
    fontSize: "1.75rem",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    FontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.57,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#393E44",
  },
  asociatedProductsTitle: {
    margin: "0 0 2rem 2rem",
    fontSize: "1rem",
    fontFamily: "Montserrat",
    fontWeight: "normal",
    FontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#9D9D9D",
  },
  balanceTitle: {
    margin: "0 0 1rem 2rem",
    fontSize: "1rem",
    fontFamily: "Montserrat",
    fontWeight: 500,
    FontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6B6B6B",
  },
  balanceAmount: {
    margin: "0 0 1rem 2rem",
    fontSize: "1.75rem",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    FontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.57,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#393E44",
  },
  showInCalendar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0 2rem 0 2rem",
    width: "18.875rem",
    height: "3.125rem",
    borderRadius: "10.4px",
    boxShadow: "4px 4px 8px 2px rgba(0,0,0,0.2)",
    alignItems: "center",
    paddingLeft: "1rem",
  },
  bankButton: {
    paddingRight: "2rem",
  },
  showInCalendarTitle: {
    fontSize: "0.875rem",
    fontFamily: "Montserrat",
    fontWeight: 500,
    FontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#393E44",
  },
  connectedWithFinerioAndLogo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: "1.5rem 0 0 0",
  },
  connectedWithFinerio: {
    fontSize: "0.875rem",
    fontFamily: "NotoSans, Montserrat",
    fontWeight: 500,
    FontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.48,
    letterSpacing: "normal",
    color: "#9D9D9D",
  },
  finerioIcon: {
    marginLeft: "0.5rem",
    height: "2rem",
    width: "6rem",
  },
  backButton2: {
    display: "flex",
    justifyContent: "center",
    margin: "3.5rem 0 0 0",
  },
  backButton2Style: {
    width: "20.8rem",
  },
  moreVertMenu: {
    width: "5.6rem",
    height: "3.25rem",
    background: "#FFFFFF",
    margin: "2rem 0 0 0",
    display: "flex",
    position: "absolute",
    top: "11.2rem",
    left: "15rem",
    borderRadius: "10.4px",
    boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down(1280)]: {
      top: "9.7rem",
      left: "87%",
    },
    [theme.breakpoints.down(1000)]: {
      top: "9.7rem",
      left: "80%",
    },
    [theme.breakpoints.down(900)]: {
      left: "80%",
    },
    [theme.breakpoints.down(680)]: {
      left: "71%",
    },
    [theme.breakpoints.down(530)]: {
      left: "63%",
    },
    [theme.breakpoints.down(440)]: {
      left: "58%",
    },
  },
  hidden: {
    display: "none",
  },
  menuItem: {},
  menuText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.875rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    color: "#7D7C7C",
    textAlign: "left",
    marginLeft: "1rem",
  },
}));
