import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme) => ({
  AsociatedProducts: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem',
    boxShadow: '4px 4px 8px 2px rgba(0,0,0,0.2)',
    borderRadius: '30px',
    [theme.breakpoints.down(1280)]: {
      height: '100%',
    },
  },
  AsociatedProductsTitle: {
    margin: '2.5rem 0 0 4rem',
    fontFamily: 'Montserrat',
    fontSize: '1rem',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#6b6b6b',
  },
  innerContainer1: {
    width: '22.063rem',
    height: '18.313rem',
    margin: 'auto',
    borderRadius: '10.4px',
    boxShadow: '4px 4px 8px 2px rgba(0,0,0,0.2)',
    backgroundColor: 'ffffff',
    [theme.breakpoints.down(1280)]: {
      width: '95%',
      margin: '1rem',
      backgroundColor: 'white',
    },
  },
  innerContainer2: {
    width: '22.063rem',
    height: '13.313rem',
    margin: 'auto',
    borderRadius: '10.4px',
    boxShadow: '4px 4px 8px 2px rgba(0,0,0,0.2)',
    backgroundColor: 'ffffff',
    [theme.breakpoints.down(1280)]: {
      width: '95%',
      margin: '1rem',
      backgroundColor: 'white',
    },
  },
  innerContainer2Invisible: {
    width: '22.063rem',
    height: '13.313rem',
    margin: 'auto',
    borderRadius: '10.4px',
  },
  ContainerRow: {
    display: 'flex',
    padding: '1.5rem 2rem 1.5rem 2rem',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    gap: '2rem',
  },
  innerContainerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  innerContainerColumn: {
    width: '11rem',
    paddingRight: '1rem',
    height: '5.3rem',
    display: 'flex',
    flexDirection: 'column',
    alingItems: 'center',
  },
  innerContainerColumnLong: {
    width: '22rem',
    height: '5.3rem',
    display: 'flex',
    flexDirection: 'column',
    alingItems: 'center',
  },
  cardTitle: {
    fontFamily: 'Montserrat',
    fontSize: '1rem',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.88,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#393e44',
    margin: '1rem 0 0.6rem 1rem',
  },
  icon: {
    margin: '1rem 1rem 0 0',
  },
  innerText1: {
    width: '9rem',
    margin: '0 0 0.4rem 1rem',
    fontFamily: 'Montserrat',
    fontSize: '0.75rem',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#6b6b6b',
  },
  innerText2: {
    width: '10rem',
    margin: '0 0 0 1rem',
    fontFamily: 'Montserrat',
    fontSize: '0.75rem',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#393e44',
  },
  innerText2Long: {
    width: '20rem',
    margin: '0 0 0 1rem',
    fontFamily: 'Montserrat',
    fontSize: '0.75rem',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#393e44',
  },
  backButton2: {
    display: 'flex',
    justifyContent: 'center',
    margin: '3.5rem 0 2rem 0',
  },
  backButton2Style: {
    width: '80%',
  },
  email: {
    overflowWrap: 'break-word',
    width: '80%',
  },
}));
