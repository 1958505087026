import React, { useState, useEffect, useContext, useCallback } from "react";
import { useMediaQuery } from "@material-ui/core";
import HeaderFinancialPortal from "@components/FinancialPortal/Header/Header";
import { LayoutOnBoarding } from "@components/Structural";
import { ButtonPrimary } from "@components";

import {
  ShoppingCartOutlined,
  SchoolOutlined,
  HouseOutlined,
  MonetizationOnOutlined,
  LocalMoviesOutlined,
  DirectionsCarOutlined,
} from "@material-ui/icons";
import { Route } from "@interfaces";
import { useStyles } from "./AccountDetails.style";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import MoreVert from "@material-ui/icons/MoreVert";
import ToggleOff from "@material-ui/icons/ToggleOff";
import { FINERIO_ICON } from "images/AWS/myAccount/index";
import { parseNumber } from "@utils";
import { 
  disableEnableFinerioCredential, 
  getFinerioCredentialByBank 
} from "@apollo";
import { useApolloClient } from "@apollo/client";
import { navigate } from "@reach/router";

interface accountDetailsProps {
  title: string | undefined;
  amount: () => void;
  backgroundColor: string | undefined;
  icon: string | undefined;
  bankId: number;
}

const AccountDetail = ({
  title,
  amount,
  backgroundColor,
  icon,
  bankId,
}: accountDetailsProps) => {
  const isMobile = useMediaQuery("(max-width: 1280px)");
  const client = useApolloClient();
  const { finerioCredential } = getFinerioCredentialByBank(bankId);

  const buttonHandler = () => {
    navigate(Route.financialPortal);
  };

  const deleteButtonHandler = async () => {
    await disableEnableFinerioCredential(client, { credentialId: Number(finerioCredential.id) }).then((resp) => {
      navigate(Route.financialPortal);
    })
  };
  
  const classes = useStyles({ color: backgroundColor });
  const [openVertMenu, setOpenVertMenu] = useState(false);

  return (
    <>
      <div className={classes.detailContainer}>
        <div className={classes.detailContainerInfo}>
          <button className={classes.buttonBack1} onClick={buttonHandler}>
            <div className={classes.buttonBackContainer}>
              <KeyboardArrowLeft />
            </div>
          </button>

          <img src={icon} className={classes.bankIcon}></img>
        </div>

        <div className={classes.containerBottomEnd}>
          <div className={classes.bankTitleAndButton}>
            <h2 className={classes.bankTitle}>{title}</h2>
            <button
              onClick={() => setOpenVertMenu(!openVertMenu)}
              className={classes.bankButton}
              name="button 2"
            >
              {<MoreVert />}
            </button>
          </div>
          <div className={openVertMenu ? classes.moreVertMenu : classes.hidden}>
            <button className={classes.menuItem} onClick={deleteButtonHandler}>
              <p className={classes.menuText}>Eliminar</p>
            </button>
          </div>
          <h4 className={classes.asociatedProductsTitle}>
            Productos asociados
          </h4>
          <h3 className={classes.balanceTitle}>SALDO</h3>
          <p className={classes.balanceAmount}>$ {parseNumber(amount())}</p>
          {/**<div className={classes.showInCalendar}>
          <h4 className={classes.showInCalendarTitle}>Mostrar en calendario </h4>
          <button onClick={buttonHandler} className={classes.bankButton} name="button 2">
            {<ToggleOff />}
          </button>
        </div> */}{" "}
          {!isMobile && (
            <div className={classes.backButton2}>
              <ButtonPrimary
                onClick={() => {
                  buttonHandler();
                }}
                text="Volver a mis productos"
                noDegraded
                className={classes.backButton2Style}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AccountDetail;
