import React from "react";
import { useMediaQuery } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import HeaderFinancialPortal from "@components/FinancialPortal/Header/Header";
import { LayoutOnBoarding } from "@components/Structural";
import { ButtonPrimary } from "@components";
import {
  ShoppingCartOutlined,
  SchoolOutlined,
  HouseOutlined,
  MonetizationOnOutlined,
  LocalMoviesOutlined,
  DirectionsCarOutlined,
} from "@material-ui/icons";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { useStyles } from "./AsociatedProducts.style";
import VisaWorldMember from "./AssociatedProductTypes/VisaWorldMember";
import HabitsDetails from "@components/FinancialPortal/HabitsDetails/HabitsDetails";
import CheckingAccount from "./AssociatedProductTypes/CheckingAccount";
import PhysicalCard from "./AssociatedProductTypes/PhysicalCard";
import VirtualCard from "./AssociatedProductTypes/VirtualCard";
import AvalStateCredit from "./AssociatedProductTypes/AvalStateCredit";
import Product from "./AssociatedProductTypes/Product";

interface productProps {
  accounts: any;
  refetchFinerioAccounts: () => void;
}

const AsociatedProducts = ({
  accounts,
  refetchFinerioAccounts,
}: productProps) => {
  const isMobile = useMediaQuery("(max-width: 1280px)");

  const buttonHandler = () => {
    navigate(Route.financialPortal);
  };

  const classes = useStyles();

  return (
    <>
      {!isMobile && (
        <div className={classes.AsociatedProducts}>
          <h3 className={classes.AsociatedProductsTitle}>
            PRODUCTOS ASOCIADOS
          </h3>
          <div className={classes.ContainerRow}>
            {accounts.map((account: any) => {
              return (
                <Product
                  AccountName={account.accountName}
                  AccountBalance={account.accountBalance}
                  AccountCurrency={account.accountCurrency}
                  AccountNumber={account.accountNumber}
                  AccountType={account.accountType}
                  Debit={account.debit}
                  Credit={account.credit}
                  key={account.id}
                  Enabled={account.enabled}
                  AccountId={Number(account.id)}
                  refetchFinerioAccounts={refetchFinerioAccounts}
                />
              );
            })}
          </div>
        </div>
      )}
      {isMobile && (
        <div>
          {accounts.map((account: any) => {
            return (
              <Product
                AccountName={account.accountName}
                AccountBalance={account.accountBalance}
                AccountCurrency={account.accountCurrency}
                AccountNumber={account.accountNumber}
                AccountType={account.accountType}
                Debit={account.debit}
                Credit={account.credit}
                key={account.accountId}
                Enabled={account.enabled}
                AccountId={Number(account.id)}
                refetchFinerioAccounts={refetchFinerioAccounts}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default AsociatedProducts;
