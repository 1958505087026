import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme>((theme) => ({
  product: {
    gap: "1rem",
  },
  innerContainer1: {
    width: "22.063rem",
    height: "24rem",
    margin: "auto",
    borderRadius: "10.4px",
    boxShadow: "4px 4px 8px 2px rgba(0,0,0,0.2)",
    backgroundColor: "ffffff",
    [theme.breakpoints.down(1280)]: {
      width: "95%",
      margin: "1rem",
      backgroundColor: "white",
    },
  },
  ContainerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "1.5rem",
    gap: "1.5rem",
  },
  innerContainerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  innerContainerColumn: {
    width: "11rem",
    paddingRight: "1rem",
    height: "3.3rem",
    display: "flex",
    flexDirection: "column",
    alingItems: "center",
  },
  innerContainerColumnLong: {
    width: "22rem",
    height: "5.3rem",
    display: "flex",
    flexDirection: "column",
    alingItems: "center",
  },
  cardTitle: {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.88,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#393e44",
    margin: "1rem 0 0.6rem 1rem",
  },
  icon: {
    margin: "1rem 1rem 0 0",
  },
  innerText1: {
    width: "9rem",
    margin: "0 0 0.4rem 1rem",
    fontFamily: "Montserrat",
    fontSize: "0.75rem",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6b6b6b",
  },
  innerText2: {
    width: "10rem",
    margin: "0 0 0 1rem",
    fontFamily: "Montserrat",
    fontSize: "0.75rem",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#393e44",
  },

  innerContainer2: {
    width: "22.063rem",
    height: "13rem",
    margin: "auto",
    borderRadius: "10.4px",
    boxShadow: "4px 4px 8px 2px rgba(0,0,0,0.2)",
    backgroundColor: "ffffff",
    paddingTop: "0.75rem",

    [theme.breakpoints.down(1280)]: {
      width: "95%",
      marginBottom: "1rem",
      marginTop: "1rem",
      backgroundColor: "white",
    },
  },

  break: {
    margin: "0.5rem 0",
  },
  breakText: {
    fontFamily: "Montserrat",
    fontSize: "0.8rem",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#393e44",
    margin: "0.5rem 0",
    marginBottom: "1.375rem",
    marginTop: "0.8125rem",
    marginLeft: "0.875rem",
  },

  breakLine: {
    height: "1px",
    backgroundColor: "#393e4491",
    margin: "0.5rem 0",
    marginTop: "0.2rem",
    marginLeft: "1.25rem",
    marginRight: "1.25rem",
  },
}));
